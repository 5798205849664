// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDu5MGygviXZXNp6xRopMrmCf2yRqRFgKk',
    appId: "1:172178994172:web:465f5e9afadd285f",
    authDomain: 'parking.dox.pub',
    databaseURL: 'https://atob-75dd3.firebaseio.com',
    projectId: 'atob-75dd3',
    storageBucket: 'atob-75dd3.appspot.com',
    messagingSenderId: '172178994172',
    measurementId: "G-9VP01NDSXJ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
